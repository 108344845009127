<template>
  <div :id="'carousel_' + props.uuid" class="carousel slide">
    <div class="carousel-indicators">
      <slot name="indicators"></slot>
    </div>
    <div class="carousel-inner">
      <slot name="items"></slot>
    </div>
    <button class="carousel-control-prev" type="button" :data-bs-target="'#carousel_' + props.uuid"
            data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" :data-bs-target="'#carousel_' + props.uuid"
            data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script setup>
import {inject, onMounted, watch} from "vue";

const Store = inject('Store');
const props = defineProps({uuid: String});

let carousel;
onMounted(() => {
  let myCarouselEl = document.getElementById('carousel_' + props.uuid)
  carousel = window.bootstrap.Carousel.getOrCreateInstance(myCarouselEl)
  carousel.to(Store.state.currentSlide)
});

watch(
  () => Store.state.currentSlide,
  currentSlide => {
    carousel.to(currentSlide)
  },
  {deep: true},
);

</script>
