<template>
  <img :src="props.src"
       :alt="props.alt"
       :class="props.class"
       class="cursor-pointer"
       @click="expanded=true"
  >
  <transition name="fade">
    <div v-if="expanded" class="modal fade show" id="loadingModal" tabindex="-1" style="display: block">
      <div class="modal-backdrop d-flex align-items-center justify-content-center" @click="expanded=false">
        <div class="w-auto h-100 py-2">
          <img :src="props.src"
               :alt="props.alt"
               class="card img-fluid w-auto h-100"
          >
        </div>
      </div>
    </div>
  </transition>

</template>

<script setup>
import {ref} from "vue";

const expanded = ref(false);
const props = defineProps({
  src: String,
  alt: String,
  class: String,
});
</script>

<style>
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
