<template>
  <div v-if="name"><b>{{ name }}</b></div>
  <div v-for="(element, elementKey) in d" class="ml-3">
    <div v-if="typeof element === 'object' && element">
      <div @click="hideShow(elementKey)" style="cursor: pointer;">
        <i class="el-icon-caret-bottom" v-if="!hideShowKey[elementKey]"></i>
        <i class="el-icon-caret-top" v-if="hideShowKey[elementKey]"></i>
        {{ elementKey }}:
        <span class="badge rounded-pill bg-primary" v-if="element">{{ countObject(element) }}</span>
      </div>
      <div v-if="hideShowKey[elementKey]">
        <d-d :d="element" :level="level+1" :name="''" v-if="hideShowKey[elementKey]"></d-d>
      </div>
    </div>
    <div v-else-if="typeof element === 'boolean'">
      {{ elementKey }}: <span class="text-info">{{ element }}</span>
    </div>
    <div v-else-if="typeof element === 'function'">
        <span @click="hideShow(elementKey)" style="cursor: pointer;">
          <i class="el-icon-caret-bottom" v-if="!hideShowKey[elementKey]"></i>
          <i class="el-icon-caret-top" v-if="hideShowKey[elementKey]"></i>
          {{ elementKey }} :
        </span>
      <span v-if="!hideShowKey[elementKey]" class="pre-formatted">function()...</span>
      <span v-if="hideShowKey[elementKey]" class="pre-formatted">{{ element }}</span>
    </div>
    <div v-else-if="typeof element === 'number'">
      {{ elementKey }}: <span class="text-warning">{{ element }}</span>
    </div>
    <div v-else-if="typeof element === 'string'">
      {{ elementKey }}: <span class="text-success pre-formatted">{{ element }}</span>
    </div>
    <div v-else-if="typeof element === 'undefined'">
      {{ elementKey }}: <span class="text-danger">undefined</span>
    </div>
    <div v-else>
      {{ elementKey }}: <span class="text-danger">null</span>
    </div>
  </div>
</template>

<script setup>
import {defineAsyncComponent, ref} from "vue";

const props = defineProps({
  d: {type: Object, required: false, default: {}},
  name: {type: String, required: false, default: ''},
  level: {type: Number, required: false, default: 0},
});
const DD = defineAsyncComponent(() => Promise.resolve(import ('./d-d.vue')))
const hideShowKey = ref({});
const countObject = function (obj) {
  let count = 0;
  for (let key in obj) if (obj.hasOwnProperty(key)) count++;
  return count;
};
const hideShow = function (key) {
  hideShowKey.value[key] = !hideShowKey.value[key];
};

</script>

<style scoped>
.pre-formatted {
  white-space: pre;
}
</style>
