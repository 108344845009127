<template>
  <transition name="slidedown">
    <div v-if="isSubmitted">
      <div class="alert alert-success" role="alert">
        <div v-html="form.ext.endPoint.formSubmitedtext"></div>
      </div>
    </div>
  </transition>

  <form @submit.prevent="onSubmit()"
        method="post"
        novalidate
        ref="aeFormRef"
        :action="form.ext.endPoint.url"
        :target="form.ext.endPoint.target"
        v-if="!isSubmitted"
  >
    <div v-for="(input) in form.ext.inputs">

      <div v-if="input.type === 'note'" v-html="input.value"></div>

      <div v-else-if="input.type === 'select'" class="row g-0 mb-3">
        <label class="col-lg-3 col-form-label text-lg-end mr-2" :for="input.uuid" v-if="!input.hideLabel">
          {{ input.label ? input.label : input.name }}
        </label>
        <div class="col">
          <div class="input-group shadow-sm" :id="'anc_'+input.uuid">
            <select :id="input.uuid"
                    :ref="input.uuid"
                    @change="validate(input)"
                    class="form-select"
                    v-model="contactForm[input.name]"
                    :name="input.name"
                    :required="input.required">
              <option v-for="(option, optionKey) in input.options"
                      :value="option.value">
                {{ option.name }}
              </option>
            </select>
            <div v-if="notHidden(input) && input.required" class="input-group-text"
                 :class="input.valid ? 'bg-light' : ''">
              <span v-if="input.valid">valid</span>
              <span v-else>required</span>
            </div>
          </div>
          <transition name="fade">
            <div v-if="!input.valid && input.valid !== undefined" style="position: absolute;">
              <div class="text-danger" style="font-size: 11px">
                {{ input.errorText ? input.errorText : 'Please complete this field' }}
              </div>
            </div>
          </transition>
        </div>
      </div>

      <div v-else-if="input.type === 'checkbox'" class="row g-0 mb-3">
        <label class="col-lg-3 col-form-label text-lg-end mr-2" :for="input.uuid" v-if="!input.hideLabel">
          {{ input.label ? input.label : input.name }}
        </label>
        <div class="col">
          <div class="input-group shadow-sm" :id="'anc_'+input.uuid">

            <div class="input-group-text">
              <input :id="input.uuid"
                     type="checkbox"
                     :ref="input.uuid"
                     v-model="contactForm[input.name]"
                     @change="validate(input)"
                     required="input.required">
            </div>

            <div class="form-control"></div>
            <div v-if="input.required" class="input-group-append">
              <span class="input-group-text bg-light" v-if="input.valid">valid</span>
              <span v-else class="input-group-text">required</span>
            </div>
          </div>
          <transition name="fade">
            <div v-if="!input.valid && input.valid !== undefined" style="position: absolute;">
              <div class="text-danger" style="font-size: 11px">
                {{ input.errorText ? input.errorText : 'Please complete this field' }}
              </div>
            </div>
          </transition>

        </div>

      </div>

      <div v-else-if="input.type === 'submit'" class="row g-0 mb-3 pt-1">
        <label class="col-lg-3 col-form-label text-lg-end mr-2 mt-1" v-if="!input.hideLabel"></label>
        <div class="col">
          <button :ref="input.uuid" @change="validate(input)" type="submit" class="btn btn-primary">Submit</button>
        </div>
      </div>

      <div v-else-if="input.type === 'image'" class="row g-0 mb-3">
        <label class="col-lg-3 col-form-label text-lg-end mr-2" v-if="!input.hideLabel"></label>
        <div class="col">
          <input :id="input.uuid"
                 :ref="input.uuid"
                 @change="validate(input)"
                 :type="input.type"
                 :name="input.name"
                 v-model="contactForm[input.name]"
                 :src="getOption(input,'src')"
                 :alt="getOption(input,'alt')"
                 :class="notHidden(input) ? 'form-control' : ''"
                 :placeholder="input.name"
                 :required="input.required">
        </div>
      </div>

      <div v-else class="row g-0" :class="notHidden(input) ? 'form-group mb-3': ''">
        <label :for="input.uuid"
               class="col-lg-3 col-form-label text-lg-end mr-2"
               v-if="notHidden(input) && !input.hideLabel">
          {{ input.label ? input.label : input.name }}
        </label>
        <div class="col">
          <div class="input-group" :class="notHidden(input) ? 'shadow-sm' : ''" :id="'anc_'+input.uuid">
            <textarea :id="input.uuid"
                      v-if="input.type === 'textarea'"
                      oninput='this.style.height = ""; this.style.height = (this.scrollHeight +2) + "px"'
                      :ref="input.uuid"
                      v-on:keyup="validate(input)"
                      :name="input.name"
                      v-model="contactForm[input.name]"
                      :class="notHidden(input) ? 'form-control' : ''"
                      :placeholder="input.label ? input.label : input.name"
                      :required="input.required"></textarea>
            <input :id="input.uuid"
                   v-else
                   :ref="input.uuid"
                   v-on:keyup="validate(input)"
                   :type="input.type"
                   :name="input.name"
                   v-model="contactForm[input.name]"
                   :class="notHidden(input) ? 'form-control' : ''"
                   :placeholder="input.label ? input.label : input.name"
                   :required="input.required">
            <div v-if="notHidden(input) && input.required" class="input-group-text"
                 :class="input.valid ? 'bg-light' : ''">
              <span v-if="input.valid">valid</span>
              <span v-else>required</span>
            </div>
          </div>
          <transition name="fade">
            <div v-if="!input.valid && input.valid !== undefined" style="position: absolute;">
              <div class="text-danger" style="font-size: 11px">
                {{ input.errorText ? input.errorText : 'Please complete this field' }}
              </div>
            </div>
          </transition>
        </div>
      </div>

    </div>
  </form>

  <!--  <d-d :d="form" name="form"></d-d>-->
  <!--  <d-d :d="contactForm" name="contactForm"></d-d>-->

</template>

<script setup>
import {onMounted, ref} from "vue";
import axios from 'axios'
// import VueScrollTo from 'vue-scrollto'
let VueScrollTo = require('vue-scrollto');
let options = {
  container: 'body',
  easing: 'ease',
  lazy: false,
  offset: -170,
  force: true,
  cancelable: true,
  onStart: function (element) {
    // scrolling started
  },
  onDone: function (element) {
    // scrolling is done
  },
  onCancel: function () {
    // scrolling has been interrupted
  },
  x: false,
  y: true
}

const props = defineProps({id: Number});
const form = ref({id: null, ext: {inputs: [], endPoint: {}}});
const contactForm = ref({});
const isSubmitted = ref(false);
const isBusy = ref(false);
const aeFormRef = ref(null);

onMounted(() => {
  axios.get(
    '/form/' + props.id
  ).then(response => {
    form.value = response.data[0];
    form.value.ext.inputs.map((value, key) => {
      contactForm.value[value.name] = value.value
    });
  }).catch(error => {
    console.log(error);
  });
})

const getOption = (input, name) => {
  return input.options.find(x => x.name === name) ? input.options.find(x => x.name === name).value : ''
}

const notHidden = (input) => {
  return input.type !== 'image' && input.type !== 'hidden';
}

const validate = (input) => {
  if (input.required && input.type !== 'note' && input.type !== 'submit' && input.type !== 'image') {
    let check;
    if (input.type === 'email') {
      check = String(contactForm.value[input.name]).toLowerCase().match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    } else check = !!contactForm.value[input.name];
    if (check) {
      input['valid'] = true
      return false;
    } else {
      input['valid'] = false
      return true;
    }
  }
}

const onSubmit = () => {
  let validatedFalse = false;
  form.value.ext.inputs.map((input, inputKey) => {
    let check = validate(input);
    if (check && !validatedFalse) {
      VueScrollTo.scrollTo('#anc_' + input.uuid, 333, options)
      validatedFalse = true;
    }
  });
  if (!validatedFalse) {
    if (form.value.ext.endPoint.type === 'External') {
      aeFormRef.value.submit();
    } else {
      isBusy.value = true;
      contactForm.value['aeFormID'] = form.value.id
      axios.post("/api/aeForm", contactForm.value).then(response => {
        isBusy.value = true;
        isSubmitted.value = true;
      }).catch(
        error => {
          isBusy.value = false;
          console.log(error.data);
        }
      );
    }
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slidedown-enter-active,
.slidedown-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.slidedown-enter-to,
.slidedown-leave-from {
  overflow: hidden;
  max-height: 1000px;
}

.slidedown-enter-from,
.slidedown-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
