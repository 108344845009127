<template>

  <transition-group name="bounce" tag="div" class="row" v-if="!isBusy">

    <div :key="'leftCol'" class="col-lg-4 mb-3" v-show="showControls">
      <CatalogueControl/>
    </div>

    <div :key="'rightCol'" class="col">

      <div class="card shadow-sm rounded-2" v-if="Catalogue.itemSelectedObject.value !== null">

        <div class="card-header pb-0 pt-1 pb-1 ps-2 shadow-sm">
          <div class="row g-0">
            <div class="col">
              <div class="h3 mb-0">
                <span @click="showControls = !showControls" class="cursor-pointer animateControl animateControlColour">
                  <i class="fas fa-chevron-circle-left" v-if="showControls" title="Show/hide controls"></i>
                  <i class="fas fa-chevron-circle-right" v-if="!showControls" title="Show/hide controls"></i>
                </span>
                {{ Catalogue.itemSelectedObject.value.name }}
              </div>
            </div>

            <div class="col-auto">
              <ae-popover
                v-if="attributesHTML"
                trigger="click"
                placement="bottom"
                :content="attributesHTML"
              />
            </div>
          </div>

        </div>

        <div class="card-body pt-2 pb-0 pl-3">
          <div class="row">
            <div class="col-md-8">
              <!--              <v-runtime-template :template="Catalogue.getDescription.value"/>-->
              <div v-html="Catalogue.getDescriptionV2.value"/>
<!--              <hr>-->
<!--              <div v-html="Catalogue.getDescription.value"/>-->
            </div>

            <div class="col-md-4" v-for="(image, imageKey) in Catalogue.itemSelectedObject.value.ext.Images">
              <div class="mb-3">
                <ae-expandable-image :src="'/imagecache/Native' + image.src"
                                     :alt="image.alt"
                                     :class="'img-fluid card shadow-sm w-100'"
                />

                <div class="text-center">
                  <div v-html="'<div>'+image.caption+'</div>'" v-if="image.caption"/>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

  </transition-group>


  <transition name="fade">
    <div v-if="isBusy" class="modal fade show" id="loadingModal" tabindex="-1" style="display: block">
      <div class="modal-backdrop d-flex align-items-center justify-content-center">
        <div class="spinner-border text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </transition>

  <!--  <d-d :d="{-->
  <!--    items: Catalogue.items.value,-->
  <!--    ItemsFiltered: Catalogue.ItemsFiltered.value,-->
  <!--    }" name="Catalogue!"/>-->

  <el-backtop />
</template>
<script setup>
import {onMounted, ref, computed, inject} from "vue";
import axios from 'axios'
import 'element-plus/es/components/backtop/style/css'
import {ElBacktop} from 'element-plus'

const Catalogue = inject('Catalogue');
const Utils = inject('Utils');
const props = defineProps({catalogueId: Number, catalogueTypeId: Number,});
const groups = ref([])
const currentPage = ref(1)
const showControls = ref(false)
const catalogueType = ref({ext: {properties: []}})
const isBusy = ref(true);
const attributesHTML = computed(() => {
  let html = '';
  if (Catalogue.catalogueType.value.ext !== undefined) {
    Catalogue.catalogueType.value.ext.properties.map((prop, key) => {
      if ((prop.type === 'select' || prop.type === 'multiple') && !prop.disabled) {
        if (prop.type === 'select') {
          html += (prop.name + ": " + Catalogue.itemSelectedObject.value.ext[prop.name] ?? '') + "<br>";
        } else if (prop.type === 'multiple') {
          html += getMultipleInfo(prop);
        }
      }
    }, html)
  }
  return html + "<!-- " + Utils.uuidV4() + " -->";
});

const getMultipleInfo = (prop) => {
  let out = [];
  if (Catalogue.itemSelectedObject.value.ext[prop.name]) {
    for (const [extKey, ext] of Object.entries(Catalogue.itemSelectedObject.value.ext[prop.name])) {
      // console.log('this.catalogueType.ext.properties', catalogueType.value.ext.properties);
      Catalogue.catalogueType.value.ext.properties.map(function (property, propertyKey) {
        if (prop.name === property.name) {
          property.options.map(function (option, optionKey) {
            extKey === option.id && ext && out.push(option.name);
          });
        }
      });
    }
  }
  return prop.name + ": " + out.join(', ') + "<br>";
}

onMounted(() => {
  let urlParts = document.documentURI.split('/');
  let plantID = urlParts.pop();

  const promise1 = axios.get('/api/catalogueItemsLookup?id=' + props.catalogueId).then(response => {
    Catalogue.items.value = response.data
    let itemsKey = null;
    Catalogue.items.value.map(function (value, key) {
      // console.log('item found:', value.name, plantID, key)
      value.Description = value.ext.Description;
      if (value.name === decodeURI(plantID)) {
        itemsKey = key;
      }
    }, itemsKey);
    Catalogue.itemSelectedObject.value = itemsKey ? Catalogue.items.value[itemsKey] : response.data[0];
    if (itemsKey === null) {
      console.log('replaceState now', itemsKey);
      window.history.replaceState({}, 'test', document.URL + "/" + response.data[0].name);
      showControls.value = true
    }
    showControls.value = true

  }).catch(error => {
    console.log(error);
  });
  const promise2 = axios.get('/api/catalogueLookup?id=' + props.catalogueId,).then(response => {
    Catalogue.groups.value = response.data
  }).catch(error => {
    console.log(error);
  });
  const promise3 = axios.get('/api/catalogueTypeLookup?id=' + props.catalogueTypeId,).then(response => {
    Catalogue.catalogueType.value = response.data[0]
  }).catch(error => {
    console.log(error);
  });
  axios.all([promise1, promise2, promise3]).then(responseArr => {
    isBusy.value = false
  });


})

</script>

<style>
mark, .mark {
  padding: 0;
  background-color: yellow;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in .5s;
  transition: opacity 0.5s ease-in;
}

.bounce-leave-active {
  animation: bounce-in .5s reverse;
  transition: opacity 0.5s ease-out;
}

.bounce-enter, .bounce-leave-to {
  opacity: 0;
}

@keyframes bounce-in {
  0% {
    transform: translateX(-400px);
  }
  100% {
    /*transform: scale(1);*/
    transform: translateX(0);
  }
}

.animateControl {
  transition: all 0.3s ease-in-out;
}

.animateControlColour {
  color: #3490dc;
}

.animateControl:hover {
  color: black;
  transform: rotate(45deg);
  filter: brightness(120%);
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

</style>
