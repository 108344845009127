import {reactive} from "vue";

const state = reactive({
    currentSlide: null,
  }
);

export default {
  state
};
