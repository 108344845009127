import {computed, ref, reactive, nextTick} from "vue";
import escapeStringRegexp from 'escape-string-regexp';

const items = ref([])

const groups = ref([])
const groupsSelected = ref([])
const treeChecked = ref([])
const catalogueType = ref({ext: {properties: []}})
const itemSelectedObject = ref({ext: {Images: []}})
const currentPage = ref(1)
const filter = ref('')
const filterExact = ref(false)
const perPage = ref(10)
const itemsTable = reactive({value: null});


const getDesc = () => {
  if (!filter.value) {
    return '<div>' + itemSelectedObject.value.ext.Description + '</div>'
  }
  let desc = cleanHtml(JSON.parse(JSON.stringify(itemSelectedObject.value.ext.Description)));
  let leftArrow = desc.split('<');
  leftArrow.map((la, laKey) => {
    let rightSlashArrow = la.split('/>');
    if (rightSlashArrow.length === 2) {
      rightSlashArrow[1] = replaceFilter(rightSlashArrow[1]);
    } else {
      let rightArrow = rightSlashArrow[0].split('>');
      if (rightArrow.length === 2) {
        rightArrow[1] = replaceFilter(rightArrow[1]);
      }
      rightSlashArrow[0] = rightArrow.join('>')
    }
    leftArrow[laKey] = rightSlashArrow.join('/>')
  }, leftArrow)
  desc = leftArrow.join('<')
  return '<div>' + desc + '</div>';
}

const getDescriptionV2 = computed(() => {
  return getDesc();
});

const cleanHtml = (html) => {
  html = html.replace(/(?:\\[rn]|[\r\n]+)+/g, "");
  let txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

const replaceFilter = (text) => {
  let filterText = escapeStringRegexp(filter.value);
  let re;
  if (filterExact.value) {
    re = new RegExp('\\b(' + filterText + ')\\b', 'gi')
  } else {
    re = new RegExp('(' + filterText + ')', 'gi');
  }
  return text.replace(re, '<mark>$1</mark>', "gi");
}

const ItemsFiltered = computed(() => {

  let filtered = [];
  if (groupsSelected.value.length) {
    items.value.map(function (item) {
      let groupCount = 0;
      item.links.catalogue.map(function (catalogue) {
        groupsSelected.value.map(function (group) {
          if (catalogue.id === group) {
            groupCount++;
          }
        });
      });
      if (groupCount + 1 === item.links.catalogue.length) {
        // +1 HACK NEEDS FIXING; don't ignore as will make non full linked groups break, but do we care?!!
        filtered.push(item);
      }
    });
  } else {
    filtered = items.value;
  }

  let userFiltered = [];
  if (filter.value) {
    let filterText = escapeStringRegexp(filter.value);

    let re;
    if (filterExact.value) re = new RegExp('\\b(' + filterText + ')\\b', 'gi');
    else re = new RegExp('(' + filterText + ')', 'gi');
    filtered.map(function (item) {
      const dom = new DOMParser().parseFromString(item.ext.Description, 'text/html');
      let desc = dom.body.textContent;
      if (desc.match(re)) {
        userFiltered.push(item);
      }
    });
    filtered = userFiltered;
    userFiltered = [];
  }


  catalogueType.value.ext.properties.map(function (prop, propKey) {

    if (prop.type === 'select' && prop.filter) {
      filtered.map(function (item, itemKey) {
        if (item.ext && item.ext[prop.name] === prop.filter) {
          userFiltered.push(item);
        }
      }, this);
      filtered = userFiltered;
      userFiltered = [];
    }

    if (multipleCheck(prop)) {
      filtered.map(function (item, itemKey) {
        let found = false;
        if (item.ext && item.ext[prop.name]) {
          for (const [itemSelectedKey, itemSelected] of Object.entries(item.ext[prop.name])) {
            if (itemSelected) {
              prop.filter.map(function (filter, filterKey) {
                if (itemSelectedKey === filter) {
                  found = true;
                }
              });
            }
          }
        }
        if (found) {
          userFiltered.push(item);
        }
      });
      filtered = userFiltered;
      userFiltered = [];
    }
  });

  if (itemsTable.value) {
    currentPage.value = 1
    nextTick(function () {
      if (ItemsFiltered.value.length) {
        itemsTable.value.setCurrentRow(ItemsFiltered.value[0])
        itemSelectedObject.value = ItemsFiltered.value[0]
      }
    }).then(() => {
    })
  }

  return filtered
});

const multipleCheck = (prop) => {
  let checked = 0;
  if (prop.type === 'multiple') {
    Object.values(prop.filter).forEach(property => {
      property && checked++;
    });
  }
  return checked;
}

export default {
  items,
  ItemsFiltered,
  groups,
  groupsSelected,
  catalogueType,
  itemSelectedObject,
  currentPage,
  filter,
  filterExact,
  perPage,
  getDescriptionV2,
  replaceFilter,
  itemsTable,
  treeChecked,
};
