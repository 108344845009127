<template>
  <div class="row g-1 mb-2 notSelectable">
    <div class="col-auto d-flex align-items-center justify-content-center">
      <el-button class="shadow-sm" size="small" data-bs-toggle="modal" data-bs-target="#helpModal">
        Help
      </el-button>
      <div class="modal fade" id="helpModal" tabindex="-1" aria-labelledby="helpModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="helpModalLabel">Plant directory help page</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p class="my-2">Help information will be coming soon</p>
              <p class="my-2">If you need help then please contact the <a
                href="mailto:webmaster@rhodogroup-rhs.org">Webmaster</a></p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col">
      <ElPagination
        background
        layout="sizes, pager"
        :total="Catalogue.ItemsFiltered.value.length"
        :page-size="Catalogue.perPage.value"
        :pager-count="9"
        :page-sizes="[5, 10, 15, 50, 100, 250, 500]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="Catalogue.currentPage.value"
        small
      />
    </div>

  </div>

  <div class="row notSelectable">
    <div class="col-6">
      <div class="card p-0 pb-2 shadow-sm mb-2"
           :class="Catalogue.groupsSelected.value.length  ? 'border-success' : ''"
      >
        <div class="card-header card-header-fix p-0 pl-2 d-flex align-items-center">
          Groups
        </div>
        <div class="mt-2">
          <el-tree :data="Catalogue.groups.value[0].children"
                   :props="{children: 'children', label: 'title'}"
                   ref="groupTree"
                   show-checkbox
                   @node-click=""
                   node-key="id"
                   :highlight-current="false"
                   :default-expand-all="true"
                   :check-on-click-node="true"
                   :expand-on-click-node="false"
                   @check="handleCheck"
          />
        </div>
      </div>

      <div v-for="property in Catalogue.catalogueType.value.ext.properties" :key="property.uuid">
        <div class="card p-0 shadow-sm mb-1"
             :class="checkFilter(property.filter)  ? 'border-success' : ''"
             v-if="(property.type === 'select' || property.type === 'multiple') && !property.disabled"
        >
          <div class="card-header p-0 m-0 ps-2 pe-2">
            <div class="row d-flex cursor-pointer" @click="showHideProperty(property)">
              <div class="col">
                {{ property.name }}
              </div>
              <div class="col-auto">
                <i v-if="property.show" class="fas fa-chevron-up"></i>
                <i v-if="!property.show" class="fas fa-chevron-down"></i>
              </div>
            </div>
          </div>
          <slide-up-down v-model="property.show" :duration="333">
            <div class="ps-2 pe-2">
              <div v-for="(option, optionKey) in property.options" :key="option.uuid">
                <div v-if="property.type === 'multiple'" class="form-check form-switch">
                  <input class="form-check-input"
                         type="checkbox"
                         v-model="property.filter[optionKey]"
                         :id="'filters_' + property.uuid + '_' + optionKey"
                         :true-value="option.id" :false-value="null"
                  >
                  <label class="form-check-label" :for="'filters_' + property.uuid + '_' + optionKey">
                    {{ option.name }}
                  </label>
                </div>
                <div v-if="property.type === 'select'" class="form-check form-switch">
                  <input class="form-check-input"
                         type="checkbox"
                         v-model="property.filter"
                         :id="'filters_' + property.uuid + '_' + optionKey"
                         :true-value="option.id" :false-value="null"
                  >
                  <label class="form-check-label" :for="'filters_' + property.uuid + '_' + optionKey">
                    {{ option.name }}
                  </label>
                </div>
              </div>
            </div>
          </slide-up-down>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="card shadow-sm mb-2 rounded-top-left-1" :class="Catalogue.filter.value ? 'border-success' : ''">
        <div class="card-header p-0">
          <div class="input-group input-group-sm border-0">
            <div class="input-group-text rounded-0 rounded-top border-bottom-0 border-top-0 border-start-0">
              <i class="fas fa-search" aria-hidden="true"></i>
            </div>
            <input type="text"
                   placeholder="Search Description"
                   v-model="Catalogue.filter.value"
                   class="form-control border-0">
            <div class="input-group-text rounded-top-right-1 border-0 cursor-pointer"
                 v-if="Catalogue.filter.value"
                 @click="Catalogue.filter.value = ''"
            >
              <i class="fas fa-times" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div class="card-body p-0 pt-1">
          <div class="mx-2">
            <div class="row">
              <div class="col">
                <div class="form-check form-switch">
                  <input class="form-check-input"
                         type="checkbox"
                         v-model="Catalogue.filterExact.value"
                         id="filterExact"
                  >
                  <label class="form-check-label" for="filterExact">
                    Exact match
                  </label>
                </div>
              </div>
              <div class="col-auto">
                  <span class="badge show bg-secondary" v-if="Catalogue.ItemsFiltered.value.length">
                    {{ Catalogue.ItemsFiltered.value.length }} found
                  </span>
                <span class="badge show bg-danger" v-else>0 found</span>
              </div>
            </div>
          </div>
        </div>
        <el-table
          :data="Catalogue.ItemsFiltered.value.slice(
              Catalogue.perPage.value * (Catalogue.currentPage.value-1),
              Catalogue.perPage.value + Catalogue.perPage.value * (Catalogue.currentPage.value-1)
            )"
          style="width: 100%; max-height:480px; overflow-y: auto;"
          size="small"
          highlight-current-row
          @current-change="selectItem"
          row-class-name="cursor-pointer"
          :current-row-key="Catalogue.ItemsFiltered.value[0] ? Catalogue.ItemsFiltered.value[0].id : null"
          row-key="id"
          stripe
          ref="itemsTable"
          :show-header="false"
          empty-text="No results"
          class="rounded-bottom"
        >
          <el-table-column prop="name" label="Name" sortable/>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script setup>
import {inject, ref} from "vue";

import 'element-plus/es/components/pagination/style/css'
import {ElPagination} from 'element-plus'
import 'element-plus/es/components/tree/style/css'
import {ElTree} from 'element-plus'
import 'element-plus/es/components/table/style/css'
import {ElTable, ElTableColumn} from 'element-plus'
import 'element-plus/es/components/button/style/css'
import {ElButton} from 'element-plus'

const Catalogue = inject('Catalogue');
const groupTree = ref(null)
const itemsTable = ref(null)
Catalogue.itemsTable.value = itemsTable;

const handleSizeChange = (perPage) => {
  // console.log(perPage);
  Catalogue.perPage.value = perPage;
  itemsTable.value.setCurrentRow(Catalogue.ItemsFiltered.value.slice(
    Catalogue.perPage.value * (Catalogue.currentPage.value - 1),
    Catalogue.perPage.value * (Catalogue.currentPage.value - 1) + 1
  )[0])
}
const handleCurrentChange = (currentPage) => {
  Catalogue.currentPage.value = currentPage
  itemsTable.value.setCurrentRow(Catalogue.ItemsFiltered.value.slice(
    Catalogue.perPage.value * (Catalogue.currentPage.value - 1),
    Catalogue.perPage.value * (Catalogue.currentPage.value - 1) + 1
  )[0])
}
const selectItem = (item) => {
  Catalogue.itemSelectedObject.value = item
}
const handleCheck = () => {
  Catalogue.groupsSelected.value = groupTree.value.getHalfCheckedKeys().concat(groupTree.value.getCheckedKeys());
}

const showHideProperty = (property) => {
  let currentShow = property.show;
  Catalogue.catalogueType.value.ext.properties.map(function (value, key) {
    value.show = false;
  })
  property.show = !currentShow;
}

const checkFilter = (filter) => {
  if (Array.isArray(filter)) {
    let empty = true;
    filter.map((value, key) => {
      if (value) empty = false
    })
    return !empty;
  }
  return !!filter;
}

</script>

<style>
.rounded-top-right-1 {
  border-top-right-radius: 0.25rem !important;
}
.rounded-top-left-1 {
  border-top-left-radius: 0.25rem !important;
}

.notSelectable {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
}

.el-tree {
  background: unset !important;
}

.el-tree-node__content {
  height: 19px;
}

.el-tree-node__label {
  font-size: 0.85em !important;
}

.card-header-fix {
  height: 30px !important;
}


.el-input__inner {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}

.el-pager li {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}

</style>
