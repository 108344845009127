window.bootstrap = require("bootstrap")
require('./fa');

import {createApp} from 'vue';

import Store from './inject/store';
import Utils from './inject/utils';
import Catalogue from './inject/catalogue';
window.app=createApp({
  inject:['Store', 'Utils', 'Catalogue']
})

import SlideUpDown from 'vue3-slide-up-down'
window.app.component('slide-up-down', SlideUpDown)

import VueLazyLoad from '@jambonn/vue-lazyload'
window.app.use(VueLazyLoad);

window.app.provide('Store', Store)
window.app.provide('Utils', Utils)
window.app.provide('Catalogue', Catalogue)

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => window.app.component(key.split('/').pop().split('.')[0], files(key).default))

require('../../public/js/./main');

window.app.mount("#app")
