<template>
  <a
    tabindex="0"
    class="h3 mb-0"
    role="button"
    data-bs-toggle="popover"
    :data-bs-trigger="props.trigger"
    :data-bs-placement="props.placement"
    title="Attributes"
    :data-bs-content="props.content"
    :id="'ae-popover_' + uuid"
  >
    <i id="popover-target-2" class="fas fa-info-circle" title="Show attributes"></i>
  </a>
</template>
<script setup>
import {inject, onMounted, watch, nextTick} from "vue";
import {Popover} from 'bootstrap'
const Utils = inject('Utils');

const props = defineProps({
  trigger: String,
  placement: String,
  content: String,
});

let popover
onMounted(() => {
  popover = new Popover(document.getElementById('ae-popover_' + uuid), {html: true})
})
const uuid = Utils.uuidV4();

watch(
  () => props.content,
  content => {
    popover.dispose();
    nextTick(function () {
      popover = new Popover(document.getElementById('ae-popover_' + uuid), {html: true})
    }).then(() => {})
  },
  {deep: true},
);

</script>

<style>
</style>
